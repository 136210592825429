export const SiteEnum = {
  BASE_PAGE: 'home',
  BASE_PAGE_ID: 0
}

export const NavTypeEnum = {
  URL: '1',
  PAGE: '2',
  MODEL: '3'
}

export const SiteColumnsNameEnum = {
  PAGE: '页面',
  LOGIN: '登陆',
  SEO: 'SEO',
  THEME: '主题',
  ASSETS: '素材库',
  LANGUAGE: '语言',
  SETTING: '配置',
  GLOBAL: '全局'
}

export const PageSetting = {
  getMenuInfo() {
    return {
      icon: 'pageSvg',
      name: SiteColumnsNameEnum.PAGE
    }
  }
}

export const LoginSetting = {
  children: [
    //
  ],
  getMenuInfo() {
    return {
      icon: 'icon-login-nor',
      name: SiteColumnsNameEnum.LOGIN
    }
  }
}

export const GlobalSetting = {
  children: [
    {
      name: SiteColumnsNameEnum.ASSETS
    },
    {
      name: SiteColumnsNameEnum.GLOBAL
    },
    {
      name: SiteColumnsNameEnum.LANGUAGE
    },
    {
      name: SiteColumnsNameEnum.SEO
    }
  ],
  getMenuInfo() {
    return {
      icon: 'iconGlobal',
      name: SiteColumnsNameEnum.GLOBAL
    }
  }
}

export const ColumnsSetting = [
  PageSetting,
  LoginSetting,
  GlobalSetting
]

export const InterCurrency = ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF', 'IDR', 'HKD', 'TWD']

export const RechargeAnyValueId = "9999"

export const EventCodeEnums = {
  STORE_VISITED: 'STORE_VISITED',
  EMAIL_LOGIN_SHOW: 'EMAIL_LOGIN_SHOW',
  EMAIL_BIND_UID: 'EMAIL_BIND_UID',
  UID_LOGIN_SHOW: 'UID_LOGIN_SHOW',
  UID_LOGIN: 'UID_LOGIN',
  PAYMENT_SHOW: 'PAYMENT_SHOW',
  EMAIL_LOGIN: 'EMAIL_LOGIN',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  PAYMENT_CHOOSE: 'PAYMENT_CHOOSE',
  PAYMENT_SUBMIT: 'PAYMENT_SUBMIT',
  PAYMENT_MIDDLE_SHOW: 'PAYMENT_MIDDLE_SHOW'
}

export const EditorWrapperClass = '.edit-content__center--wrapper'
