import { promiseToEditPrices } from '~/utils/prices'
import { mergePromiseToSame } from '~/utils/api'

const Api = {
  StoreVirtualItems: '/api/v2/store/virtual-item/get',
  MerchantVirtualItems: '/api/v2/merchant/virtual-item/get',
  VirtualItem: '/api/v2/store/virtual-item/detail', // /store/virtual-item
  SAVE_SHOP_CART: '/api/v2/store/shopping/cart/add',
  SHOP_CART_LIST: '/api/v2/store/shopping/cart/list',
  REMOVE_SHOP_CART_GOODS: '/api/v2/store/shopping/cart/del',
  SHOP_CART_GOODS_UPDATE: '/api/v2/store/shopping/cart/update',
  CARD_LIST: '/api/v2/store/virtual-item/card/list',
  GET_CARD_GIFT: '/api/v2/store/virtual-item/card/gift-receive'
}
export default (request) => {
  return {
    //  虚拟物品列表-查询
    getVirtualItems: (params, options) => {
      const { editing } = options
      params && (params.enabled = 1)
      return promiseToEditPrices(
        mergePromiseToSame(
          editing ? Api.MerchantVirtualItems : Api.StoreVirtualItems,
          params,
          options,
          request
        )
      )
    },
    //  虚拟物品详情-查询
    getVirtualItem: (params, options) => {
      return promiseToEditPrices(
        request.get(Api.VirtualItem, { params, ...options })
      )
    },
    saveToShopCart: (data) => request.post(Api.SAVE_SHOP_CART, data),
    getShopCart: (params) => request.get(Api.SHOP_CART_LIST, { params }),
    deleteShopCartGoods: (data) => request.post(Api.REMOVE_SHOP_CART_GOODS, data),
    updateShopCartGoods: (data) => request.post(Api.SHOP_CART_GOODS_UPDATE, data),
    getCardList: (params) => request.get(Api.CARD_LIST, { params }),
    getCardGift: (data) => request.post(Api.GET_CARD_GIFT, data),
    getAllGoodsList: (params, editing) => {
      return editing ? request.get('/api/v2/merchant/item/get-by-groups', { params }) : request.get('/api/v2/store/item/get-by-groups', { params })
    }
  }
}
